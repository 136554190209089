import React, {Component} from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const isBrowser = typeof window !== "undefined";

function getMobileOperatingSystem() {
    if(!isBrowser){
      return "unknown";
    }

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

  const Text = () => (
    <>
    TESTEZ <br /> GRATUITEMENT
    </>
  );
  
  const ButtonDownload = (props) =>{ 
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          type
        }
      }
    }
    `);

    var mobile =data.site.siteMetadata.type == 'site' ?  (props.mobile || getMobileOperatingSystem()) : 'from-blog';
  
    var text = props.text ||  Text();
    var className = props.className ? ' ' + props.className : '';

    switch(mobile){
      case "iOS":
        return (
          <a className={'button ios' + className} href="https://apps.apple.com/fr/app/apple-store/id1494489358">{text}</a>
          );
      case "Android":
        return (
          <a className={'button android' + className} href="https://play.google.com/store/apps/details?id=com.neocra.conetist">{text}</a>
          );
      case "from-blog":
        return (
          <a className="button" href="https://www.conetist.com">{text}</a>
          );
      default:
        return(
          <Link className="button"  to="/application">
            {text}
            </Link>
          )
      }
  }

  export default ButtonDownload;
  